/* orizontal scroll - Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
    background: #222;
}

*::-webkit-scrollbar-thumb {
    background: #a2a2a2;
    border-radius: 10px;
}

body {
    -webkit-overflow-scrolling: touch;
    position: relative;
}

ion-content {
    --background: #000000;
}

ion-segment {
    --background: #666;
}

ion-segment-button {
    --indicator-color: var(--yellowOrange);
    --background-checked: #666;
    --color: rgb(255, 255, 255);
    --color-checked: black;

}

ion-segment-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-toolbar {
    --background: #222;
}

.searchbar-input {
    border-radius: 20px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
i,
a,
li,
div,
span {
    word-wrap: break-word;
    word-break: break-word
}

/* input style */
.form-control {
    background-color: var(--dark666) !important;
    color: #fff !important;
    font-weight: 500 !important;
}

.input-group>span.input-group-text {
    background-color: var(--dark444);
    color: #fff;
    border: 1px solid var(--dark444);
    border-radius: 20px;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
}

.form-control:focus {
    border-color: var(--yellowOrange);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 4px var(--yellowOrange);
}

/* CardPenRejAnnouncementReducedForm edit modal*/
.announcementEditModal {
    background: linear-gradient(to bottom, #333, #2a606c);
}

.trashButtonImage {
    height: 50px;
    width: 50px;
    border-radius: 28px;
    font-size: 23px;
}

.trashButtonImage:hover {
    background-color: #c32750 !important;
    color: #fff !important;
    transition: .4s linear .1s;
}

/* input type=file */
.myInputFile {
    border-radius: 10px;
}

/* input form.control */
.form-control {
    border-radius: 20px;
    font-weight: 700;
    padding: 6px 20px;
    color: #444;
}

.form-label {
    font-weight: 400;
    font-size: 1.2rem;
}

.form-check-input:checked {
    background-color: #d5a82f;
    border-color: #d5a82f;
}

#chat-input::placeholder {
    color: var(--lightGray);
}

/* bootstrap dropdown custom */
.dropdown-menu.show {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
    padding: 15px;
    margin: 15px 0;
    justify-content: center;
}

/* categories border active */
.myBorderAcqua {
    border: 3px solid var(--tiffany)
}

.myBorderRed {
    border: 3px solid var(--fucsiaRed)
}

.myBorderWhiteGray {
    border: 1px solid #f0f0f0;
}

.borderActive {
    border: 3px solid #ffffff
}

.borderActiveYellow {
    border: 3px solid var(--yellowOrange) !important
}

/* price package border */
.myBorderActive {
    border: 3px solid rgb(125, 125, 125);
}


/* Distribution area plus and trash button */
.plusDistributionArea {
    height: 30px;
    width: 30px;
    border-radius: 15px;
}

.disabledPlus,
.disabledPlus>.plusDistributionArea {
    pointer-events: none;
    opacity: 0.7;
    color: #8f8f8f !important;
}

/* sign in form - deler/manufacturer selector */
.delManSelector {
    border: 4px solid #6c757d;
}

.delManSelectorActive {
    border: 4px solid var(--yellowOrange);
    background-color: #333 !important;
}

.delManSelector>i {
    display: none;
}

.delManSelectorActive>i {
    top: -18px;
    left: -18px;
    color: var(--yellowOrange);
    font-size: 1.8rem;
}

/* sign in form - progress bar */
.progressStepBar>div:first-child {
    width: 215px;
}

.progressStepBar>div:first-child>div {
    height: 15px;
    width: 15px;
    border-radius: 8px;
    background-color: var(--dark666);
    z-index: 1;
}

.progressStepBar>div:first-child>.active {
    background-color: var(--yellowOrange);
}

.progressStepBar>div:last-child {
    height: 5px;
    width: 200px;
    z-index: 2;
}

.progressStepBar>div:nth-child(2) {
    height: 7px;
    width: 200px;
    border: 1px solid var(--dark666);
}

/* dropdown menu bootstrap */

.currencySearchInput {
    border-style: none;
    border-bottom: 1px solid gray;
}

.currencySearchInput:focus {
    outline: none;
}

.dropdown-menu.show {
    max-height: 400px;
    overflow-y: scroll;
}

.customDropdown {
    position: absolute;
    padding: 1rem;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    z-index: 3;
    font-weight: 600;
    border-radius: 10px;
    background-color: var(--dark444) !important;
    color: #fff !important;
    border: 1px solid var(--dark444) !important;
}

.customDropdownSm {
    max-height: 30vh
}

.customDropdownMd {
    max-height: 50vh
}

.customDropdown>div:hover {
    background: var(--yellowOrange);
    color: var(--dark222);
    transition: .1s ease-in-out .1s;
}

/* active user/account icon */
.activeUserIcon {
    top: -72px;
}

.activeUserIcon i {
    font-size: 2.2rem
}

label.form-label {
    font-weight: 600;
}

/* PAYEMENT */
.invoiceDataTable h6 {
    padding: 5px 0;
    font-weight: 600;
}

.invoiceDataTable span {
    font-weight: 400;
    font-size: 1.1rem;
}

/* Package Icons */
.packageIcon100px {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

/* Vertical Grid */
.verticalGrid {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-auto-rows: 1px;
    justify-content: center;
}

.overlayVerticalGrid {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: 100%;
    background: linear-gradient(to top,
            hsla(0, 0%, 15%, 0.738) 19%,
            hsla(0, 0%, 15%, 0.541) 34%,
            hsla(0, 0%, 15%, 0.382) 47%,
            hsla(0, 0%, 15%, 0.278) 56.3%,
            hsla(0, 0%, 15%, 0.194) 65%,
            hsla(0, 0%, 15%, 0.126) 73%,
            hsla(0, 0%, 15%, 0.075) 80.2%,
            hsla(0, 0%, 15%, 0.042) 86.1%,
            hsla(0, 0%, 15%, 0.021) 91%,
            hsla(0, 0%, 15%, 0.008) 95.2%,
            hsla(0, 0%, 15%, 0.002) 98.2%,
            hsla(0, 0%, 250%, 0) 100%);
}

/* overlay */
.overlayBottomThin {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top,
            hsla(0, 0%, 44%, 0.738) 2%,
            hsla(0, 0%, 67%, 0.367) 8%,
            hsla(0, 0%, 82%, 0.212) 12%,
            hsla(0, 0%, 100%, 0) 15%,
            hsla(0, 0%, 100%, 0) 100%);
}

/* Form Announcement */
.form-control {
    border: var(--dark666);
}

/* Notify Dot notifiche count contatore */
.notifyDotChat {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    top: -8px;
    right: -8px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #71a902;
    color: #222;
    background-color: var(--chatColor);
}

.notifyDotLikes {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    top: -8px;
    right: -8px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #c32750;
    color: #fff;
    background-color: var(--fucsiaRed);
}

.notifyGeneralChat {
    height: 20px;
    top: -12px;
    right: -9px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}


/* zoomIn zoomOut */
.normalZoom>span {
    font-weight: 800;
    font-size: 13px;
}

.zoomInClass>span {
    font-weight: 700;
    font-size: 24px;
    padding: 0 16px !important;
}

/* loading bar chat */
.loadingChatBar {
    height: 4px;
    background-color: #ffffff00;
    width: 100%;
}

/* dot dots */
.dotImg {
    height: 4px;
    width: 4px;
    margin: 2px;
    margin-bottom: 7px;
    border-radius: 3px
}

.dotImgActive {
    height: 6px;
    width: 6px;
}

/* usersHeartIcon */
.usersHeartIcon {
    font-size: 40px;
}

.usersHeartIcon>i:last-child {
    bottom: -5px;
    right: -8px;
    font-size: 25px;
}

/* imgUserConnections */
.imgUserConnections {
    height: 40px;
    width: 40px;
}

/* icon quickAction */
.quickActionsMenu {
    z-index: 99;
}

.quickActionsMenu>i:hover {
    color: var(--fucsiaRed);
}